<template>
    <div class="selectClass">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" left-arrow @click-left="$router.go(-1)">
            <template #title>
                <span>直播课包列表</span>
            </template>
        </van-nav-bar>

        <div class="list">
            <div class="item" v-for="(course, key) in ActiveList" :key="key" @click="setDetails(course)">
                <div class="right">
                    <div class="title">
                        <div><img class="title_img" src="@/assets/all_Icon/drawable-xxhdpi/icon_recording.png" />{{ course.name }}</div>
                        <div class="text">
                            <div>{{course.playDate}}<span>直播时间：</span>{{course.plaTime}}</div>
                        </div>
                    </div>

                    <div class="mark">
                        <div class="mark_study" @click="goToLive(course)">进入</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { liveList_tc } from "@/api/study_course.js";
import {kbt} from '@/utils/utils.js'

export default {
    data() {
        return {
            ActiveList: [],
            typeList: [],
            ids: -1
        };
    },
    created() {
        console.log('直播课包列表------------------')
        this.ActiveList = [];
        this.typeList = [];
        this.init();
    },
    methods: {

        setDetails(item) {
            
        },
        init() {
            liveList_tc(0, this.$route.query.id).then((res) => {
                if (res.data.code == 0) {
                    this.ActiveList = res.data.data;
                } else {

                }
            })
        },
        goToLive(course) {
            //打开直播
            window.sessionStorage.setItem('liveName',course.name)
            let coursePackageId = ''
            let courseId = ''
            let state = '' //state=1直播 state=2回放
            if(course.coursePackageId){
                coursePackageId =  course.coursePackageId
            }else{
                coursePackageId =  null
            }
            if(course.courseId){
                courseId =  course.courseId
            }else{
                courseId = ''
            }
            if(course.state == 2){
                state = '2'
            }else{state = '1'}
            
            let channelId = kbt.encrypt(course.channelId) //直播id加密
          
           this.$router.push({
               path:'/zhibo',
               query:{
                   id:channelId,
                   state:course.state,
                   classId:course.id,
                   coursePackageId:coursePackageId,
                   courseId:courseId
               }
           });
        }
    },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}
.title_box {
    margin-top: 15px;
    width: calc(100% - 14px);
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    margin-left: 5px;
    margin-right: 7px;
    overflow-x: auto;
    .title_box_item {
        margin-left: 7px;
        width: 71px;
        height: 36px;
        background: #f8f8f8;
        border-radius: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        line-height: 36px;
        text-align: center;
    }
    .active_title_box_item {
        background: #e7ecff;
        color: #5d7dff;
    }
}
.selectClass {
    height: 100vh;
    font-family: PingFangSC-Medium, PingFang SC;
    position: relative;
    // background-color: pink;
    .list {
        padding: 0px 12px;
        .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 14px 0;
            &:last-child {
                border: none;
            }

            .right {
                width: 100%;
                border-bottom: 1px solid #dddddd;
                padding-bottom: 15px;
                display: flex;
                justify-content: space-between;
                .title {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    color: #333333;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-wrap: break-word;
                    .title_img {
                        width: 30px;
                        height: 15px;
                        margin-right: 10px;
                    }
                }
                .text {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-wrap: break-word;
                    margin: 6px 0 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .question_cirle-continer {
                        width: calc(100% - 93px);
                        height: 10px;
                        background: #e7ecff;
                        border-radius: 5px;
                        .question_cirle-yes {
                            width: 50%;
                            height: 10px;
                            background: #5f7dff;
                            border-radius: 5px;
                        }
                    }
                }
                .mark {
                    font-size: 13px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    .mark_title {
                        font-size: 12px;
                        color: #cccccc;
                    }
                    .mark_study {
                        color: #ffffff;
                        width: 69px;
                        height: 25px;
                        background: #5f7dff;
                        border-radius: 17px;
                        text-align: center;
                        line-height: 25px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>

